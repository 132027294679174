.cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;

    @include respond(tablet) {
        gap: 7%;
    }


    &__item {
        width: 23.5%;
        margin-bottom: 5.5rem;
        text-decoration: none;
        transition: opacity .4s;

        &:hover {
            opacity: .5;
        }

        @include respond(lg-laptop) {
            width: 32.6%;
        }
        @include respond(md-laptop) {
            width: 49.5%;
        }
        @include respond(tablet) {
            width: 46.5%;
            margin-bottom: 6rem;
        }
        @include respond(lg-phone) {
            width: 100%;
        }
    }
    &__redirect {

    }
    &__icon {

    }
    &__img {
        width: 100%;
        margin-bottom: 1.2rem;
        border-radius: 11.0374px;
    }
    &__text {
        margin-left: .9rem;
    }
}