.points {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.blue-decoration {
    position: absolute;
    right: 0;
    top: 10rem;
}

.color-shape {
    position: absolute;
    top: 42rem;
    left: 74rem;
}

.decoration {
    position: absolute;
}

#decoration1 {top: 58rem;}
#decoration2 {right: 4rem; top: 76rem;}
#decoration3 {right: 0; top: 148rem;}
#decoration4 {right: 76px; top: 225rem;}
#decoration5 {top: 298rem;}

@include respond(laptop) {
    #decoration4 {top: 317rem; }
}

@include respond(tablet) {
    #decoration2 { top: 99rem; }
   
}
