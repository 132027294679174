

.btn {
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .3s;
    font-size: 1.6rem;


    &:focus {
        transform: scale(.95);
    }

    @include respond(big-desktop) { font-size: 1.7rem; }
    @include respond(md-laptop) { font-size: 1.6rem; }
}

.btn--main {
    display: inline-block;
    font-family: "SF Pro Text", "SF Pro Display", sans-serif;
    padding: 1.3rem 1.5rem;
    border-radius: 6px;
    color: #fff;
    background-color: #000;

    &:hover {
        opacity: .8;
    }

    @include respond(laptop) {
        margin-top: 2.4rem;
    }
}

.btn--small {
    padding: .5rem 1.2rem !important;
    border-radius: 15px;

    @include respond(tablet) {
        height: 3rem;
        margin: auto 0;
    }
}

.btn--secondary {
    display: inline-block;
    border-radius: 2.5px;
    background-color: #1c7a25;
    color: #fff;
    padding: .8rem 3.2rem;
    text-decoration: none;

    &:hover {
        background-color: #23a89f;
    }
}

.btn--tertiary {
    padding: .4rem 4.4rem;
    background-color: #000;
    color: #fff;
    font-size: 1.8rem;
    text-decoration: none;
    border-radius: 6px;

    &:hover {
        opacity: .8;
    }

    @include respond(sm-phone) {
        margin-top: 1.8rem;
        display: block;
    }
}