.swiper-slide {
  &__bg {
    width: 100%;
    height: 100%;
  }
  &__goto {
    @include absCenter;
    left: 39%;
  }
  &__details p {
    color: #000;
    text-decoration: none;
  }
}

#panteras {
  background-image: linear-gradient(
      to right bottom,
      rgba(#000000, 0.658),
      rgba(#000000, 0.9)
    ),
    url(../media/panteras.png);
}
#foxtrot {
  background-image: linear-gradient(
      to right bottom,
      rgba(#000000, 0.658),
      rgba(#000000, 0.9)
    ),
    url(../media/foxtrot.png);
}
#annette {
  background-image: linear-gradient(
      to right bottom,
      rgba(#000000, 0.658),
      rgba(#000000, 0.9)
    ),
    url(../media/annette.png);
}
#topcamdrone {
  background-image: linear-gradient(
      to right bottom,
      rgba(#000000, 0.658),
      rgba(#000000, 0.9)
    ),
    url(../media/topcamdrone.png);
}
