.privacitat {
    grid-column: center-start / center-end;
    grid-row: 1;
    padding: 9rem 0;

    & strong {
        display: block;
        margin: 1rem 0;
        font-size: 2.6rem;
        color: #EEEEF5;
    }
    & p {
        font-size: 1.5rem;
        color: #fff;
    }
    & a {
        color: var(--primary-color);
    }
}

.content {
    grid-column: center-start / center-end;
    grid-row: 1;
}