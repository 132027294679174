.bar {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border: 3px solid #000;
    border-radius: 15px;
    padding: .5rem 1.2rem;
    width: 61%;
    max-width: 77rem;
    margin: 0 auto;
    margin-bottom: var(--default-margin-bottom-huge);


    @include respond(lg-laptop){
        width: 100%;
    }

    @include respond(tablet) {
        &__icons {
            flex-flow: column;
        }
    }
    

    &__icons {
        display: flex;
    }
    &__ico {
        width: 50%;
        min-width: 3.2rem;
        margin-right: .8rem;

        @include respond(tablet) {
            width: 100%;
            margin-right: 0;
        }
    }
    &__text {
        margin: auto 0;
        margin-right: 3rem;
    }
}