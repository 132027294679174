.me {
    padding-top: 11.8rem;
    padding-bottom: 13rem;

    &__bg {
        background-color: #ad4224;
    }

    &__decoration {
        position: absolute;
        right: 8rem;
        bottom: 5rem;
    }


    &__profile {
        width: 25rem;
        position: absolute;
        top: 26rem;
    }

    &__landscape {
        position: absolute;
        width: 100%;
    }
    &__img {
        max-width: 64rem !important;
    }

    @include respond(laptop) {
        padding: 9rem 0;
    }
}



#about-1, #about-2 {
    position: absolute;
}
#about-1 {
    top: 30rem;
    left: 2rem;
}
#about-2 {
    left: 45%;
    top: 5rem;
    bottom: 2rem;
}
