.footer {
  align-self: flex-end;

  &__main {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 5rem;

    @include respond(tablet) {
      padding: 0 0;
    }

    & strong {
      margin: auto 0;
    }
    & strong a {
      font-size: 5.7rem;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.952);
      @include respond(big-desktop) {
        font-size: 7.5rem;
      }
      @include respond(lg-tablet) {
        font-size: 5.5rem;
      }
      @include respond(tablet) {
        font-size: 3.3rem;
      }
      @include respond(lg-phone) {
        font-size: 2.3rem;
      }

    }

    @include respond(lg-phone) {
      & dl,
      & strong {
        width: 100%;
      }
    }
  }

  &__col1 {
    width: 48.8%;
    margin: auto 0;
    @include respond(lg-laptop) {
      width: 100%;
      text-align: center;
    }
  }
  &__col2 {
    width: 50%;
    text-align: right;

    @include respond(lg-laptop) {
      width: 100%;
      text-align: center;
    }
    & img {
      @include respond(big-desktop) {
        max-width: 66.2rem;
      }
      @include respond(tablet) {
        max-width: 30rem;
      }
 
      width: 100%; height: 100%; max-width: 50.2rem;
    }
  }

  &__title {
    font-size: 3.6rem;
    margin-bottom: 1.2rem;
    font-weight: 700;
  }
  &__link {
    font-size: 2.3rem;
    text-decoration: none;
    color: #000;
  }
}

.subfooter {
  background-color: #1f1d4c;
  padding: 3rem 0;

  &__flex {
    display: flex;
    justify-content: space-between;
  }
  &__list {
    display: inline-block;
    margin: auto 0;
  }
  &__item {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 2.8rem;
    }
  }
  &__link {
    font-size: 1.5em;
    color: #fff;
    text-decoration: none;
  }
}
