.wrapper, .navigation {
    display: grid;
    grid-template-columns: [sidebar-start] minmax(2.8rem, 1fr) [sidebar-end center-start] repeat(10, [col-start] minmax(0, 11.4rem) [col-end]) [center-end leftbar-start] minmax(2.8rem, 1fr) [leftbar-end];
    grid-column-gap: 7.8rem;
    grid-column: 1 / -1;

    @include respond(md-laptop) {
        grid-template-columns: [sidebar-start] minmax(.8rem, 1fr) [sidebar-end center-start] repeat(10, [col-start] minmax(0, 11.4rem) [col-end]) [center-end leftbar-start] minmax(.8rem, 1fr) [leftbar-end];
    }
    @include respond(lg-tablet) {
        grid-column-gap: 2%;
    }


    &__inner {
        grid-column: center-start / center-end;
        z-index: 9;
    }
}

.content {
    padding: calc(var(--menu-height) + 3rem) 0;
    grid-column: center-start / center-end;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    gap: 3.5rem;
}

.col-text {

    width: 76.9%;
    margin: auto 0;
    @include respond(laptop) {
        width: 100%;
    }
}

.left { text-align: left !important; @include respond(lg-phone) { text-align: center !important; } }


.col-img {
    position: absolute;
    top: 11rem;
    right: 8px;

    & img {
        width: 100%;
        max-width: 57rem;

        @include respond(big-desktop) {
            max-width: 59rem;
        }
    }

    @include respond(md-laptop) {
        & img {
            max-width: 40rem;
        }
    }
    @include respond(laptop) {
        position: inherit;
        & img {
            max-width: 30rem;
        }
    }
    @include respond(lg-tablet) {
        text-align: left;
        width: 100%;
    }
    @include respond(lg-phone) {
        position: static;
        text-align: center;
    }
}

.col-6 {
    width: 57.4%; }
.col-5 {
    width: 50%; }
.col-7 {
    width: 66.8%; }
.col-4 {
    width: 42.6%;}
.col-3 {
    width: 33.2%;}