@font-face {
    font-family: 'Poppins';
    src: url('/css/typo/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/css/typo/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/css/typo/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/css/typo/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/css/typo/Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


.heading-1 {
    word-wrap: break-word;
    font-size: 7rem;
    font-weight: bold;
    letter-spacing: 1.58px;
    line-height: 81.55px;
    color: #5F596E;

    &--black { color: #000 }
    & span { color: var(--accent-color) }

    @include respond(lg-laptop) {
        font-size: 6rem;
    }
    @include respond(md-laptop) {
        font-size: 5.4rem;
    }
    @include respond(lg-tablet) {
        letter-spacing: initial;
        line-height: initial;
        font-size: 3.4rem;
    }

    @include respond(lg-phone) {
        letter-spacing: initial;
        line-height: initial;
    }
    @include respond(sm-phone) {
        font-size: 3rem;
    }

}

.heading-2 {
    word-wrap: break-word;
    font-size: 6.395rem;
    width: 64.5%;
    font-weight: 900;
    line-height: 7.93rem;
    font-weight: 700;
    color: #04004D;
    word-wrap: break-word;



    @include respond(lg-laptop) {
        font-size: 4rem;
    }
    @include respond(lg-tablet) {
        line-height: initial;
    }
    @include respond(md-laptop) {
        width: 100%;
    }
    @include respond(sm-phone) {
        font-size: 3.6rem;
    }
}

.heading-3 {
    word-wrap: break-word;
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 3.5rem;
    color: #04004D;
    // Check this property, perhaps it's SF Pro Display Heavy / Black
    .h3--black { color: #000 }
    @include respond(sm-phone) {
        font-size: 3.6rem;
    }
}

.heading-4 {
    word-wrap: break-word;
    font-size: 6.4rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;

    @include respond(lg-phone) {
        font-size: 5.3rem;
    }
    @include respond(sm-phone) {
        font-size: 4.3rem;
    }
}

.heading-6 {
    word-wrap: break-word;
    color: #000;
    font-size: 1.876rem;
}

.paragraph {
    font-family: "SF Pro Text", sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.70);

    @include respond(lg-laptop) {
        font-size: 1.8rem;
    }

}

.p--white {
    color: rgba(255, 255, 255, 0.7);
}
.h--white {
    color: #fff;
}