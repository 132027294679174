.u-margin-bottom-vsm { margin-bottom: .9rem;}
.u-margin-bottom-sm { margin-bottom: 2.4rem;}
.u-margin-bottom-md { 
    margin-bottom: 4.8rem;
    @include respond(laptop) {
        margin-bottom: 1.8rem;
    }
}
.u-margin-bottom-hg { margin-bottom: 6.8rem;}
.upper { text-transform: uppercase; }
.gradient-1 { background: linear-gradient(160.3deg, #0F1D60 12.21%, rgba(187, 63, 63, 0) 111.28%); }
.u-align-right { text-align: right; }
.u-center { text-align: center; }
.u-vertical-center { margin: auto 0; }
.black { color: #000; }


