.skills {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 65%;

    &__item {
        background-color: #276EF1;
        border-radius: 30px;
        font-size: 1.2rem;
        padding: .8rem 1.2rem;
    }
}