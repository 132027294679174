/* MODAL WINDOW */
.modal, .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    transition: all 0.5s;
    box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.3);

}

.modal {
    overflow-y: scroll;
    height: 40rem;
}
.modal {
    background-color: #f3f3f3;
    padding: 5rem 6rem;

    &__list {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 2.5rem;
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 48%;
        font-size: 1.8rem;
        color: rgba(0, 0, 0, 0.705);
        z-index: 1;

    }
    &__header {
        font-size: 3.25rem;
        margin-bottom: 3rem;
        line-height: 1.5;
    }
    &__enum {
        display: inline-block;
        position: relative;
        background-color: rgb(0, 255, 42);
        color: #fff;
        font-size: 1.5rem;
        width: 3rem;
        height: 3rem;
        margin-right: .8rem;
        border-radius: 50%;
        vertical-align: middle;

        & span { @include absCenter; }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    transition: all 0.5s;
}

.btn--close-modal {
    font-family: inherit;
    color: inherit;
    position: absolute;
    top: 0.5rem;
    right: 2rem;
    font-size: 4rem;
    cursor: pointer;
    border: none;
    background: none;
    z-index: 1;
}

.hidden {
    visibility: hidden;
    opacity: 0;
}

// Popup CV
.popup {
    display: flex;
    background-color: #fff;
    border-radius: 3px;
    padding: 4rem 4.5rem;
    width: 101rem;
    color: #000;

    & .btn--close-modal {
        color: #791dff;
    }

    &__col1 {
        width: 70%;
    }

    &__header {
        font-size: 2.9rem;
        margin-bottom: 3.3rem;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }

    &__group:last-child() {
        margin-bottom: 2.3rem;
    }

    &__input {
        font-size: 1.5rem;
        padding: 1.5rem 2rem;
        border-radius: 2px;
        background-color: rgba(rgb(235, 235, 235), .5);
        border: none;
        // Instead of defining a font-family, we use this value so it inherit the main font-family of the webpage because for some reason,
        // the browser doesn't do this automatically.
        font-family: inherit;
        color: #000;
        // We use this property with the transparent value so it doesn't move because of adding 3 extra pixels for the border on the focus state.
        border-bottom: 3px solid transparent;
        width: 86.9%;
        display: block;
        outline: none;
        &:focus {
            outline: none;
            // We always should show which element is in the focused state for accesibility reasons
            box-shadow: 0 1rem 2rem rgba(#000, .1);
            border-bottom: 3px solid rgb(57, 206, 27);

        }
        // When the user is focusing the input and wrote a invalid value
        &:focus:invalid {
            border-bottom: 3px solid rgb(231, 122, 20);
        }
        &::-webkit-input-placeholder {
            color: red;
        }
    }
    &__label {
        font-size: 1.2rem;
        font-weight: 700;
        margin-left: 2rem;
        margin-top: .7rem;
        display: block;
        transition: all .3s;
    }
    &__input:placeholder-shown + &__label {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4.4rem);
    }
    &__radio-group {
        width: 12rem;
        display: inline-block;
        margin-top: 1.3rem;
        margin-bottom: 3.3rem;
    }
    &__radio-input {
        display: none;
    }
    &__radio-label {
        font-size: 1.6rem;
        cursor: pointer;
        position: relative;
        padding-left: 4rem;
    }

    &__radio-button {
        height: 3rem;
        width: 3rem;
        border: 5px solid var(--primary-color);
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: -.4rem;
        &::after {
            content: "";
            display: block;
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: pink;
            opacity: 0;
            transition: opacity .2s; 
        }
    }
    &__radio-input:checked ~ &__radio-label &__radio-button::after {
        opacity: 1;
    }
    
}