/*
    This file should be modified carefully
*/

@mixin respond($breakpoint) {
    @if $breakpoint == sm-phone {
        @media only screen and (max-width: 20.5em) { @content }; // 320px (600px / 16px = 37.5em)
    }
    @if $breakpoint == md-phone {
        @media only screen and (max-width: 23.4375em) { @content }; // 375px 
    }
    @if $breakpoint == lg-phone {
        @media only screen and (max-width: 26.75625em) { @content }; // 425px
    }
    @if $breakpoint == tablet {
        @media only screen and (max-width: 48em) { @content }; // 768px
    }
    @if $breakpoint == lg-tablet {
        @media only screen and (max-width: 57.25em) { @content }; // 900px
    }
    @if $breakpoint == laptop {
        @media only screen and (max-width: 64.75em) { @content }; // 1036px
    }
    @if $breakpoint == md-laptop {
        @media only screen and (max-width: 75em) { @content }; // 1200px
    }
    @if $breakpoint == lg-laptop {
        @media only screen and (max-width: 90em) { @content }; // 1440px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 120em) { @content }; // 1920px
    }
    @if $breakpoint == ultrabig-desktop {
        @media only screen and (min-width: 160em) { @content }; // 2560px
    }
}


@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin verticalCenter {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}

@mixin defaultPadding {
    padding: 8rem 0;
}