.sticky-activator {
    position: absolute;
    top: 100px;
    background-color: black;
    width: 300px;
    height: 100px;
    padding: 2rem 8rem;
    visibility: hidden;
}

.navigation {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    z-index: 10;


    &__wrapper {
        display: flex;
        justify-content: space-between;
        grid-column: center-start / center-end;
        width: 100%;
    }

    &__content {
        margin: auto 0;
        transition: all .3s;


    }

    &__checkbox,
    &__label,
    &__icon {
        display: none;
        z-index: 11
    }

    &__label {
        width: 5rem;
        height: 5rem;
        cursor: pointer;
    }

    &__logo {
        position: relative;
        cursor: pointer;

        @include respond(md-laptop) {
            margin: auto 0;
        }

        & span {
            display: inline-block;
            font-size: 1.6rem;
            color: #000;
        }
    }

    &__icon {
        width: 100%;
    }

    & ul {
        margin: auto 0;
        display: inline-block;

        &:not(:last-child) {
            margin-right: 8.5rem;
        }
    }

    &__img {
        vertical-align: middle;
        margin-right: 1.4rem;
    }

    & span {
        vertical-align: middle;
    }

    &__item {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 7.6rem;
        }
    }

    &__link {
        text-transform: uppercase;
        color: #000;
        text-decoration: none;
        font-size: 1.5rem;
        transition: all .3s;
    }

    @include respond(md-laptop) {
        grid-column: 1 / -1;

        &__label,
        &__icon {
            display: inline-block;
        }

        &__checkbox:checked~&__content {
            right: 0;
        }

        &__content {
            display: block;
            position: fixed;
            top: 0;
            width: 35rem;
            right: -35rem;
            height: 100%;
            background-color: #ffcdcd;
            z-index: 9;
            padding: 3.5rem 4.5rem;
        }

        &__list {
            margin: 0;
        }

        &__item {
            display: block;

            &:last-child {
                margin-bottom: 3.4rem;
            }
        }

        &__link {
            color: #000;
            font-size: 4.5rem;
        }

    }

    @include respond(md-laptop) {
        padding: 0 0;
    }

    @include respond(lg-phone) {
        &__content {
            width: 23rem;
            right: -23rem;
            padding: 3.5rem 2.5rem;
        }

        &__link {
            font-size: 2.5rem;
        }
    }
}



.nav--dark a,
.nav--dark span {
    color: #000;
}

.sticky {
    position: fixed;
    top: 0;
    background-color: #fff;
    transition: all .3s;
    width: 100%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

    & .navigation__link {
        color: #000;
    }

    @include respond(laptop) {
        padding-left: 0;
        padding-right: 0;
    }
}

.sidebar {
    position: fixed;
    left: 0;
    width: 10rem;
    height: 100%;
    z-index: 10;

    &__list {
        position: relative;
        top: 40%;
        text-align: center;
    }

    &__item {
        list-style-type: none;

        &:not(:last-child) {
            margin-bottom: 2.4rem;
        }
    }

    &__link {
        list-style-type: none;
    }

    @include respond(lg-tablet) {
        width: 100%;
        bottom: 0;
        background-color: #fff;
        position: initial;
        grid-row: 8;

        &__list {
            top: 0;
            padding: 1.5rem 0;
        }

        &__item {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 2.5rem;
                margin-bottom: 0;
            }
        }
    }
}