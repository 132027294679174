.bg--homepage {
  // background-image: url(../media/noise.png);
  // background-size: cover;
  position: absolute;
  top: -8rem;
  width: 100%;
  height: 160.3vh;

  @include respond(laptop) {
    height: 236vh;
  }
}

.hero {
  margin-top: 11rem;
  margin-bottom: var(--default-margin-bottom-medium);
  & button {
    margin-right: 3.5rem;
  }
  &__inner {
    // Menu : 7.2rem
    margin-bottom: 19rem;

    @include respond(lg-phone) {
      margin-top: 0;
    }
  }

  &__role {
    color: #2f2b71;
    font-size: 2rem;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 23.5%;
    text-transform: uppercase;
    margin-bottom: 1.1rem;
  }

  &__subtitle {
    width: 64%;

    @include respond(lg-tablet) {
      width: 100%;
    }
  }

  @include respond(laptop) {
    padding-top: 2rem;
  }
  @include respond(lg-phone) {
    margin-top: 5rem;
  }
}

.creacio {
  position: relative;
  padding: 20rem 0;

  @include respond(laptop) {
    padding: 9rem 0;
  }
}
.pclients {
  margin-bottom: 15rem;
}
.projectes {
  position: relative;
  margin-bottom: var(--default-margin-bottom-medium);

  &__subtitle {
    width: 55%;
    color: rgba(0, 0, 0, 0.7);

    @include respond(tablet) {
      width: 100%;
    }
    @include respond(laptop) {
      color: #fff;
    }
  }

  &__decoration {
    width: 3rem;
  }

  @include respond(laptop) {
    padding-top: 4rem;
  }
}

.habilitats {
  margin-bottom: var(--default-margin-bottom-medium);

  &__flex {
    background-color: #000;
    padding: 7.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 3%;

    @include respond(tablet) {
      padding: 3rem;
    }
  }
  &__item {
    width: 30.3333%;

    @include respond(md-laptop) {
      width: 47.8%;
      margin-bottom: 5rem;
    }
    @include respond(lg-phone) {
      width: 100%;
    }
  }
  &__ico {
    margin-bottom: 1.4rem;
  }
  @include respond(laptop) {
    padding-top: 3rem;
  }
}

.courses {
  margin-bottom: var(--default-margin-bottom-medium);

  &__flex {
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 9.7rem;
    }
  }
  &__card {
    position: relative;
    width: 30.3333%;
    background-color: #122156;
    border-radius: 33px;

    & img {
      vertical-align: middle;
    }

    &:not(:last-child) {
      margin-right: 4.5rem;
    }
  }

  &__section {
    padding-bottom: 9rem;
  }
  &__content {
    position: relative;
    padding: 3.4rem;
    z-index: 1;
  }

  &__card2 {
    top: var(--course-top-margin);
  }
  &__card3 {
    top: calc(var(--course-top-margin) * 2);
  }

  &__decoration {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__square {
    position: relative;
    width: 8.3rem;
    height: 8.3rem;
  }

  $square-width-neg: -8.3rem;

  @include respond(lg-laptop) {
    &__flex {
      gap: 5rem;
    }
    &__card {
      width: 44.333%;
      flex-grow: 2;
      &:nth-child(2) {
        margin-right: 0;
      }
    }
    &__card2,
    &__card3 {
      top: 0;
    }
  }
  @include respond(lg-tablet) {
    &__flex {
      gap: 3.2rem;
    }
    &__card {
      width: 100%;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  #squ1 {
    background-color: #4aeded;
    left: $square-width-neg;
  }
  #squ2,
  #squ3,
  #squ4 {
    top: $square-width-neg;
  }
  #squ2 {
    background-color: #4a95ed;
  }
  #squ3 {
    background-color: #af4aed;
  }
  #squ4 {
    background-color: #eddd4a;
  }

  &__heading {
    display: block;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 700;
    color: #fff;
    width: 70%;
    @include respond(md-phone) {
      width: 100%;
      font-size: 1.6rem;
    }
  }

  &__tech img {
    width: 4rem;
    &:not(:last-child) {
      margin-right: 1.7rem;
    }
  }
  &__characteristics {
    display: flex;
    gap: 1rem;
  }
  &__dataitem {
    background-color: black;
    color: #fff;
    font-size: 1.337rem;
    padding: 0.9rem 1.2rem;
  }
}

// .downicon {
//     position: absolute;
//     bottom: 12rem;
//     left: 50%;
//     cursor: pointer;
//     z-index: 9;

//     &__hex {
//         position: absolute;
//     }
//     &__arrow {
//         position: absolute;
//         top: 40px;
//         left: 22px;
//         animation: goDown 1s infinite;
//     }

// }
