*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

:root {
    --bg-color: #1335E7;
    --primary-color: #5A05AF;
    --accent-color: #FF7697;
    --menu-item-color: #fff;
    --menu-item-color-hover: rgb(216, 216, 216);
   

    // Spacing
    --menu-item-spacing: 2.5rem;
    --default-section-padding: 11.5rem 0;
    
    // Size
    --default-margin-bottom-huge: 14.5rem;
    --default-margin-bottom-medium: 9.5rem;
    --default-margin-bottom-small: 4.5rem;

    --btn-default-font-size: 1.8rem;
    --menu-height: 5rem;
    --menu-height-neg: -5rem;
    --sidebar-max-width: 10rem;
    --img-size-default: 94.4rem;
    --icon-size-sm: 25.36px;
    --icon-size-md: 2.4rem;
    --icon-size-lg: 9.2rem;
    --menu-word-size: 2rem;
    --title-size-big: 7.2rem;
    --title-size-md: 3.6rem;
    --title-size-sm: 2.5rem;
    --heading-size-big: 6.5rem;
    --paragraph-size: 2.7rem;
    --course-top-margin: 4.6rem;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
    scroll-behavior: smooth;
}

body {
    display: grid;
    font-family: 'Poppins', sans-serif;
    align-items: start;
    background-image: url(../media/noise.svg);
    background-size: cover;
    overflow-x: hidden;
}

